<template>
    <div>
        <b-modal
            :id="'modal-inscription-' + visio.id"
            class="d-flex justify-content-center align-items-center"
            content-class="shadow"
            hide-footer
            title="Inscription des stagiaires"
        >
            <BSpinner v-if="loading" label="Loading..."></BSpinner>
            <div v-else>
                <b-button class="mb-2" variant="primary" @click="inscritAll">
                    <b-icon class="mr-2" icon="people-fill"></b-icon>
                    Inscrire tous les stagiaires
                </b-button>
                <div v-if="errors.length > 0">
                    <b-alert v-for="error in errors" :key="error.id" dismissible show variant="danger">
                        {{ error.message }}
                    </b-alert>
                </div>
                <b-table :fields="fields" :items.sync="stagiaires" hover responsive show-empty>
                    <template #cell(inscrit)="row">
                        <i
                            :id="'icon-' + row.item.user_id"
                            v-b-tooltip.hover.top="row.item.infos.email ? '' : 'Ce stagiaire n\'a pas d\'email'"
                            :class="row.item.inscrit ? 'fa-user-check' : row.item.infos.email ? 'fa-user-plus' : 'fa-user-lock'"
                            :style="row.item.inscrit ? 'color: green' : row.item.infos.email ? 'color: grey' : 'color: #F64E60'"
                            class="fas"
                            @click="inscritUser(row.item.user_id)"
                        ></i>
                        <b-spinner :id="'spinner-' + row.item.user_id" class="d-none" small variant="primary"></b-spinner>
                    </template>
                    <template #head(inscrit)="scope">
                        Statut
                        <b-icon
                            v-b-tooltip.hover.top="
                                `Cliquez sur les icons à gauche pour inscrire/désinscrire un stagiaire à la vidéoconférence.`
                            "
                            class="ml-1"
                            icon="info-circle"
                        ></b-icon>
                    </template>
                    <template #empty="scope">
                        Aucun stagiaire.
                    </template>
                </b-table>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Integer from 'vuelidate/lib/validators/integer';
import { GET_CLASSROOM } from '../../../core/services/store/api/classroom.service';
import ApiService from '../../../core/services/api.service';

export default {
    name: 'RegisterIntern',
    props: {
        visio: {
            type: Integer,
            required: true
        }
    },
    data() {
        return {
            loading: true,
            stagiairesSession: {},
            stagiaires: [],
            errors: [],
            fields: [
                { key: 'inscrit', label: 'Inscrit', sortable: false },
                { key: 'infos.lastname', label: 'Nom', sortable: true },
                { key: 'infos.firstname', label: 'Prénom', sortable: true }
            ]
        };
    },
    mounted() {
        this.checkTuteur([1]);
        if (this.$store.getters.currentUser.role === 'admin') {
            this.admin = true;
        }
        this.getClassroom();
    },
    methods: {
        checkTuteur(rightIds) {
            if (
                this.$store.getters.currentUser &&
                this.$store.getters.currentUser.rights &&
                this.$store.getters.currentUser.rights.academy
            ) {
                this.$store.getters.currentUser.rights.academy.forEach(right => {
                    if (rightIds.includes(right.right_id)) {
                        this.tuteur = true;
                    }
                });
            }
        },
        //réccupérer les stagiaires inscrits à la session
        getClassroom() {
            this.$store
                .dispatch(GET_CLASSROOM, this.visio.session_id)
                .then(response => {
                    //Si l'utilisateur connecté est un tuteur, on vérifie qu'il est bien tuteur de la session
                    if (this.tuteur) {
                        if (response.data.tuteur_id !== this.$store.getters.currentUser.id) {
                            this.$router.push({ name: 'dashboard' });
                        } else {
                            this.loading = false;
                        }
                    } else {
                        this.loading = false;
                    }
                    this.stagiairesSession = response.data.stagiaires;
                })
                .finally(() => {
                    this.compareStagiaires();
                });
        },
        //create a new object with stagiaires who are and aren't in visio.users with new property inscrit
        compareStagiaires() {
            this.stagiaires = this.stagiairesSession.map(stagiaire => {
                stagiaire.inscrit = this.visio.users.some(user => stagiaire.user_id === user.user_id);
                return stagiaire;
            });
        },
        async inscritUser(userId) {
            // find if userid have an email in stagiaires.infos.email and not already inscrit
            const stagiaire = this.stagiaires.find(stagiaire => stagiaire.user_id === userId);
            if (stagiaire.infos.email) {
                //get icon and spinner
                const icon = document.getElementById('icon-' + userId);
                const spinner = document.getElementById('spinner-' + userId);
                //change icon to spinner
                icon.classList.add('d-none');
                spinner.classList.remove('d-none');
                //send request
                ApiService.post(
                    stagiaire.inscrit
                        ? process.env.VUE_APP_API_URL + '/academy/videoconference/user/' + this.visio.id + '/delete'
                        : process.env.VUE_APP_API_URL + '/academy/videoconference/user/' + this.visio.id,
                    { users: [userId] }
                )
                    .then(response => {
                        this.visio.users = response.data.visioUsers;
                        this.compareStagiaires();
                        //on affiche tout les icons et on masque tout les spinners
                        if (response.data.errors) {
                            //push errors in array and add an id to the error
                            response.data.errors.forEach(error => {
                                this.errors.push({ id: error.user_id, message: error.message });
                            });
                        }
                        icon.classList.remove('d-none');
                        spinner.classList.add('d-none');
                    })
                    .catch(() => {
                        icon.classList.remove('d-none');
                        spinner.classList.add('d-none');
                    });
            }
        },
        inscritAll() {
            // this.errors = [];
            //tout les userid des stagiaires qui ne sont pas inscrits et qui ont une adresse email
            const usersId = this.stagiaires
                .filter(stagiaire => !stagiaire.inscrit && stagiaire.infos.email)
                .map(stagiaire => stagiaire.user_id);
            // const usersId = this.stagiaires.filter(stagiaire => !stagiaire.inscrit).map(stagiaire => stagiaire.user_id);
            //on masque tout les icons et on affiche tout les spinners
            usersId.forEach(userId => {
                const icon = document.getElementById('icon-' + userId);
                const spinner = document.getElementById('spinner-' + userId);
                icon.classList.add('d-none');
                spinner.classList.remove('d-none');
            });
            ApiService.post(process.env.VUE_APP_API_URL + '/academy/videoconference/user/' + this.visio.id, { users: usersId }).then(
                response => {
                    //on change le statut de tout les stagiaires
                    this.visio.users = response.data.visioUsers;
                    this.compareStagiaires();
                    //on affiche tout les icons et on masque tout les spinners
                    if (response.data.errors) {
                        //push errors in array and add an id to the error
                        response.data.errors.forEach(error => {
                            this.errors.push({ id: error.user_id, message: error.message });
                        });
                    }
                    usersId.forEach(userId => {
                        const icon = document.getElementById('icon-' + userId);
                        const spinner = document.getElementById('spinner-' + userId);
                        icon.classList.remove('d-none');
                        spinner.classList.add('d-none');
                    });
                }
            );
        }
    }
};
</script>
